<template>
  <div class="row">
    <div class="col-12 col-lg-5">
      <div class="card mb-4">
        <div class="card-header">
          <h3 class="card-tile mb-0">Information client</h3>
        </div>
        <div class="card-body">
          <div class="mb-3">
            <H5 class="form-label">Nom </H5>
            <input
              type="text"
              class="form-control"
              placeholder="Nom"
              v-model="clientInfo.last_name"
              readonly
            />
          </div>
          <div class="mb-3">
            <H5 class="form-label">Prénom </H5>
            <input
              class="form-control"
              placeholder="Prénom"
              v-model="clientInfo.first_name"
              readonly
            />
          </div>

          <div class="mb-3">
            <H5 class="form-label">Identifiant </H5>
            <input
              class="form-control"
              placeholder="Identifiant"
              v-model="clientInfo.username"
              readonly
            />
          </div>

          <div class="mb-3">
            <H5 class="form-label">Email </H5>
            <input
              class="form-control"
              placeholder="Email"
              readonly
              v-model="clientInfo.email"
            />
          </div>
          <div class="mb-3">
            <H5 class="form-label">Date inscription </H5>
            <input
              class="form-control"
              placeholder="Date inscription"
              readonly
              v-model="dateInscription"
            />
          </div>
          <div class="mb-3">
            <H5 class="form-label">Derniere activité </H5>
            <input
              class="form-control"
              placeholder="Derniere activité"
              readonly
              v-model="derniereActivite"
            />
          </div>
        </div>
      </div>
    </div>
    <div class="col-12 col-lg-7">
      <div class="card mb-4">
        <div class="card-header">
          <h3 class="card-title mb-0">Liste commandes</h3>
        </div>
        <div>
          <b-table
            :items="clientOrder"
            :fields="fields"
            :busy="isBusy"
            striped
            hover
          >
            <template #table-busy>
              <div class="text-center text-primary my-2">
                <b-spinner class="align-middle"></b-spinner>
                <strong style="margin-left: 5px">Loading...</strong>
              </div>
            </template>
            <template #cell(status)="data">
              <p
                class="mb-0"
                v-text="resolveCommandesStatusVariantAndIcon(data.value).text"
              ></p>
            </template>
            <template #cell(total)="data">
              <p class="mb-0">{{ data.value }} DT</p>
            </template>
            <template #cell(actions)="data">
              <div class="text-nowrap">
                <feather-icon
                  :id="`invoice-row-${data.item.id}-file-icon`"
                  icon="FileIcon"
                  size="16"
                  class="mx-1"
                  @click="redirectToDetailFacture(data.item.id)"
                />
                <b-tooltip
                  title="Voir la facture"
                  :target="`invoice-row-${data.item.id}-file-icon`"
                />
              </div>
            </template>
          </b-table>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import axios from "axios";
import websiteURL from "@/websiteURL";
import { BTable, BSpinner, BTooltip } from "bootstrap-vue";
import SettingsCommandes from "../Commandes/SettingsCommandes";
export default {
  components: {
    BTable,
    BSpinner,
    BTooltip,
  },
  data() {
    return {
      clientInfo: [],
      clientOrder: [],
      isBusy: false,
      fields: [
        {
          label: "Numéro commande",
          key: "id",
          thClass: "text-center",
          tdClass: "text-center",
        },
        {
          label: "Status",
          key: "status",
          thClass: "text-center",
          tdClass: "text-center",
        },
        {
          label: "Prix",
          key: "total",
          thClass: "text-center",
          tdClass: "text-center",
        },
        {
          label: "Actions",
          key: "actions",
          thClass: "text-center",
          tdClass: "text-center",
        },
      ],
      dateInscription: "",
      derniereActivite: "",
    };
  },
  setup() {
    const { resolveCommandesStatusVariantAndIcon } = SettingsCommandes();

    return {
      resolveCommandesStatusVariantAndIcon,
    };
  },
  async beforeRouteEnter(to, from, next) {
    const apiUrl =
      websiteURL.WEBSITE + `wp-json/wc/v3/customers/${to.params.id}`;
    const consumerKey = websiteURL.CONSUMERKEY;
    const consumerSecret = websiteURL.CONSUMERSECRET;
    try {
      const response = await axios.get(apiUrl, {
        auth: {
          username: consumerKey,
          password: consumerSecret,
        },
      });
      next((vm) => {
        vm.clientInfo = response.data;
        vm.dateInscription = vm.clientInfo.date_created.slice(0, 10);
        vm.derniereActivite = vm.clientInfo.date_modified.slice(0, 10);
      });
    } catch (error) {
      alert("Une erreur s'est produite, veuillez réessayer plus tard", error);
      next(null);
    }
  },
  methods: {
    //Permet la redirection vers la facture
    async redirectToDetailFacture(item) {
      this.$router.push({
        name: "Detail Facture",
        params: { id: item },
      });
    },
    //Permet de récuperer les commande d'un client spécifique
    async clientOrders() {
      this.isBusy = true;
      const apiUrl = websiteURL.WEBSITE + `wp-json/wc/v3/orders`;
      const consumerKey = websiteURL.CONSUMERKEY;
      const consumerSecret = websiteURL.CONSUMERSECRET;
      const params = {
        customer: this.clientInfo.id,
      };

      try {
        const response = await axios.get(apiUrl, {
          auth: {
            username: consumerKey,
            password: consumerSecret,
          },
          params,
        });
        this.clientOrder = response.data;
        this.isBusy = false;
      } catch (error) {
        alert(
          "Une erreur s'est produite, veuillez réessayer plus tard, merci",
          error
        );
        this.clientOrder = [];
        this.isBusy = false;
      }
    },
  },
  mounted() {
    this.clientOrders();
  },
};
</script>
